.site-footer {
    background: #9a050c ;
      
      padding: 30px 0;
      color: #fff;
    }
    
    .container {
      max-width: 1100px;
      margin: auto;
      overflow: hidden;
      padding: 0 10px;
    }
    
    .footer-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
    
    .copyright {
      font-size: 16px;
      margin-bottom: 10px;
      color: #fff;
    }
    
    .footer-links {
      list-style: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 10px;
      margin-left: 30px;
    }
    
    .footer-links li {
      margin-right: 20px;
      font-size: 15px;
    }
    
    .footer-links a {
      color: #fff;
      text-decoration: none;
      transition: color 0.3s ease;
    }
    
    .footer-links a:hover {
      color: #ccc;
    }
    
    @media (max-width: 768px) {
      .footer-content {
        flex-direction: column;
      }
    
      .footer-links {
        margin-top: 20px;
      }
    }