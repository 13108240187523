body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

hr {
  width: 200px;
  text-align: center;
  background: #9a050C;
  color: #9a050C;
  height: 1px;
}

.missionpara{
  font-size: 20px;
  text-align: center;

}

.mission {
  background-image: url(./images/company.jpg);
  /* width: 100%; */
  height: 400px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.margin {
  margin-top: 90px;
  margin-bottom: 90px;

}

/* //////////////// */
.btn{
border-radius: 20px;
padding: 10px 20px;
background: #9a050C;
color: #fff;
transition: 1s all ease;
box-shadow: 5px 5px 10px #9a050C;
}
.btn:hover{
  background-color: #ccc;
  color: #9a050C;
  font-weight: 700;
}
.btnn{
  border-radius: 20px;
  padding: 10px 20px;
  background: #fff;
  color: #9a050C;
  font-weight: 700;
  transition: 1s all ease;
  box-shadow: 5px 5px 10px #fff;
  }
  .btnn:hover{
    background-color: #ccc;
    color: #9a050C;
    font-weight: 700;
  }


/* video css */
.video{
  position: relative;
  width: 100%;
  height: 350px;
  /* height: auto; */
  overflow: hidden;
}
.content{
  position: relative;
  z-index: 2; /* Ensure content is above the video */
  text-align: center;
  color: white;
  align-self: center;
  
}
.content p{
  font-size: 160px;
  color: #fff;
  font-weight: 600;
  
}
.back-video{
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 1;
  transform: translate(-50%, -50%);
}
@media(max-width: 1122px){
  .content p{
    font-size: 140px;
    font-weight: 500;
  }
}
@media(max-width: 916px){
  .content p{
    font-size: 120px;
    font-weight: 500;
  }
}
@media(max-width: 780px){
  .content p{
    font-size: 100px;
    font-weight: 500;
  }
}
@media(max-width: 660px){
  .content p{
   margin-top: 50px;
    font-size: 80px;
    font-weight: 500;
  }
}
@media(max-width: 526px){
  .content p{
    margin-top: 50px;
    font-size: 50px;
    font-weight: 500;
  }
}
@media(max-width: 421px){
  .content p{
    margin-left: 20px;
    margin-top: 80px;
    font-size: 50px;
    font-weight: 500;
  }
}
@media(max-width: 354px){
  .content p{
    margin-left: 20px;
    margin-top: 100px;
    font-size: 35px;
    font-weight: 500;
  }
}

:root {
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-white: #fff;
  --clr-red: #9a050c;
  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
}
/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
  /* color: var(--clr-grey-5); */
}
.product{
  color: #000;
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
/*  global classes */

/* section */
.section {
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);
}

@media screen and (min-width: 992px) {
  .section {
    width: 95vw;
  }
}

/*
=============== 
Menu
===============
*/

.menu1 {
  padding: 5rem 0;
}
.title {
  text-align: center;
  margin-bottom: 2rem;
}
.underline {
  width: 10rem;
  height: 0.25rem;
  background: var(--clr-red);
  margin-left: auto;
  margin-right: auto;
}
.btn-container {
  margin-bottom: 4rem;
  display: flex;
  justify-content: center;
}
.filter-btn {
  background: transparent;
  border-color: transparent;
  font-size: 1rem;
  text-transform: capitalize;
  margin: 0 0.5rem;
  letter-spacing: 1px;
  padding: 0.375rem 0.75rem;
  color: var(--clr-red);
  cursor: pointer;
  transition: var(--transition);
  border-radius: var(--radius);
}
.filter-btn:hover {
  background: var(--clr-red);
  color: var(--clr-white);
}
.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
  display: grid;
  gap: 3rem 2rem;
  justify-items: center;
}
.menu-item {
  display: grid;
  gap: 1rem 2rem;
  max-width: 25rem;
}
.photo {
  object-fit: cover;
  height: 200px;
  width: 100%;
  /* width: 250px; */
  /* border: 0.25rem solid var(--clr-gold); */
  border-radius: var(--radius);
  display: block;
}
.item-info header {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px dotted var(--clr-grey-5);
}
.item-info h4 {
  margin-bottom: 0.5rem;
}
.price {
  color: var(--clr-red);
}
.item-text {
  margin-bottom: 0;
  padding-top: 1rem;
}

@media screen and (min-width: 768px) {
  .menu-item {
    grid-template-columns: 225px 1fr;
    gap: 0 1.25rem;
    max-width: 40rem;
  }
  .photo {
    height: 175px;
  }
}
@media screen and (min-width: 1200px) {
  .section-center {
    width: 95vw;
    grid-template-columns: 1fr 1fr;
  }
  .photo {
    height: 150px;
  }
}

.pro{
  background-color: #E9ECEE;
  margin-top: 30px;
  margin-bottom: 30px;
 padding: 10px;
}
.product{
  font-weight: bold;
  /* color: #fff; */
}

.mission{
  margin-top: 150px;
  margin-bottom: 50px;
}

#img{
  height: 300px;
  /* object-fit: cover; */
  width: 100%;
  
}
.v1 {
  border-left: 6px solid #9a050c;
  height: 120px;
  letter-spacing: 1px;
}
@media screen and (max-width: 1170px) {
  .v1{
    height: 155px ;
  }
}
@media screen and (max-width: 991px) {
  .v1{
    height: 200px ;
  }
}
@media screen and (max-width: 497px) {
  .v1{
    height: 150px ;
  }
}
@media screen and (max-width: 449px) {
  .v1{
    height: 165px ;
  }
}
@media screen and (max-width: 401px) {
  .v1{
    height: 185px ;
  }
}
@media screen and (max-width: 360px) {
  .v1{
    height: 200px ;
  }
}
.line{
  letter-spacing: 1px;
}
.vertical-line{
border: 1px solid #9a050c;
height: 60px;
}
/* #main{
  background-image: url(./images/background.jpg);
 
} */
.crumb:hover{
  color: #9a050c;
  text-decoration: none;
}


/* //about para media query */
#para{
  margin-top: 20px;
  font-size: 18px;
}
@media(max-width: 416px){
  #para{
    margin-top: 3px;
    font-size: 17px;
  }
}
