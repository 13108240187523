.skincare-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.circle-background {
    width: 150px;
    height: 140px;
    background-color: #d40610;
    /* Replace with your desired background color */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 5px 10px #9a050C;
    position: relative;
}

.circle-outline {
    width: 170px;
    /* Slightly larger than the background circle */
    height: 160px;
    border: 5px solid #9a050C;
    /* Border matches the circle background */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
   
}

.product-image {
    /* width: 110px;
    height: 200px; */
    margin-bottom: 52px;
    /* transform: rotateX(15deg) rotateY(-15deg);
    transition: transform 0.3s ease-in-out; */
}

.product-image:hover {
    transform: rotateX(0deg) rotateY(0deg) scale(1.05);
}


.slick-prev,
.slick-next {
    color: black;
    /* Change the color of the arrows to black */
}

.slick-prev:before,
.slick-next:before {
    color: black;
    /* Change the arrow icons to black */
}

#card {
    width: 285px;
    height: 300px;
    /* background-color: #d40610; */
    /* Replace with your desired background color */
    border-radius: 5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 5px 10px #e8eef5;
    position: relative;
    margin-right: 20px;
    overflow: hidden;
    
   
}

#card-img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    overflow: hidden;
    margin: 0;
    padding: 0;
    
}
#card:hover {
    cursor: pointer;
    transform: rotateX(0deg) rotateY(0deg) scale(1.05);
}

.bg-left-half {
    position: relative; }
    .bg-left-half:before {
      position: absolute;
      width: 50%;
      height: 100%;
      z-index: -1;
      content: "";
      left: 0;
      top: 0;
      background-color: #9a050C;
    
      
    }