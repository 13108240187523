/* //about css */
.about{
    background-color: #f4f4f4;
    /* text-align: center; */
}

  
  .left-side {
    flex: 1; /* Take up equal space */
    padding: 20px; /* Add some padding */
    background-color: #f4f4f4; /* Light background color */
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
  }
  
  .content {
    color: #000;
    text-align: left;
    /* max-width: 600px; */
     /* Limit the width of the content */
  }
  .content p{
    color: #000;
    text-align: left;
  }
  
 
  
  .svg-container {
    width: 100%; /* Full width */
    height: 100%; /* Full height */
     /* Position absolute to fill the right side */
    top: 0; /* Align to the top */
    left: 0; /* Align to the left */
  }
  
  circle {
    transition: transform 0.1s ease; /* Smooth transition for the rotating object */
  }
  
  /* Optional: Style for the rotating object */
  .rotating-object {
    fill: #9a050C; /* Color of the rotating object */
  }

  .welcome{
    background: #9a050C;
  }

  /* #card{
    width: 35rem;
    height: 250px;

  } */

  /* @media screen and (max-width: 991px) {
    
  } */