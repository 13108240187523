* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: 'Times New Roman', Times, serif; */
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: #000;
}

.header {
  /* position: fixed; */
  width: 100%;
  z-index : 1000;
}

.top-bar {
  background-color: #000;
}

.top-bar__content {
  height: 30px;
  max-width: 1200px;
  padding: 0 30px;
  margin: 0 auto;
  display: flex;
  justify-content: end;
  align-items: center;
  column-gap: 20px;
  font-size: 15px;
  color: #fff;
}

.icon {
  margin-right: 3px;
  color: #9a050C;
}

.logo {
  vertical-align: middle;
  display: flex;
  column-gap: 10px;
  align-items: center;
}

.logo__img {
  height: 110px;
  /* width: 200px; */
}

.logo__text {
  font-size: 20px;
  color: #fff;
  font-weight: 500;
  letter-spacing: -0.5px;
  margin-left: 0px;
  margin-top: 5px;
  /* color: #F00000; */

}
.logo__text:hover{
 
  text-decoration: none;
  
}

nav {
  height: 80px;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  padding: 0px 50px 0px 100px;

}

nav .logo {
  font-size: 10px;
  /* color: #fff; */
  font-weight: 500;
  letter-spacing: -0.5px;
  margin-left: 0px;
  font-family: 'Times New Roman', Times, serif;
}

nav ul {
  display: flex;
  list-style: none;
  margin: auto;
}

nav ul li {
  margin: 0px 5px;
  font-family: 'Times New Roman', Times, serif;
}

nav ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;

  padding: 8px 10px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

nav ul li a:hover {
  text-decoration: none;
  color: #fff;
}

nav .menu-btn i {
  font-size: 22px;
  cursor: pointer;
  display: none;
}

#click {
  display: none;
}


@media (max-width: 1062px) {
  #click:checked~.menu-btn i:before {
    content: "\f00d";
  }

  nav ul {
    position: absolute;
    top: 110px;
    left: -100%;
    background: #111;
    height: 100vh;
    width: 80%;
    display: block;
    transition: all 0.3s ease;
    z-index: 1100;
  }

  #click:checked~ul {
    left: 0%;

  }

  nav ul li {
    margin: 40px 0;
    /* text-align: center; */

  }

  nav ul li a {
    font-size: 20px;
    display: block;
  }

  nav ul li a:hover,
  nav ul li a.active {
    color: #9a050C;
    background: none;
  }

  nav .menu-btn i {
    display: block;
  }
}

@media(max-width:482px) {
  .top-bar {
    padding: 10px 0;
  }

  nav {

    justify-content: space-between;
    padding: 0px 50px 0px 0px;
  }

}

@media(max-width:416px) {
  .logo__text {
    display: none;
  }

  .top-bar__content {
    display: block;
  }

  nav {

    justify-content: space-between;
    padding: 0px 50px 0px 60px;
  }
}


.active {
  background-color: #fff;
  color: #000;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;


  /* padding: 8px 10px; */
  border-radius: 5px;
  transition: all 0.3s ease;
}


.active a,
.active i {
  color: #000;
}
.active a:hover,
.active i:hover{
  color: #000;
}